import { lazy } from 'react';
const grpalloaction = lazy(() => import('./grpallocation'));

const grpRoutes = [
  {
    path: '/grpallocation',
    component: grpalloaction
  }
];

export default grpRoutes;