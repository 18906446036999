import { lazy } from 'react';

const farmerQrCreation = lazy(() => import('./farmerQrCreation'));

const farmerQrCreationRoutes = [
  {
    path: '/farmerQrCreation',
    component: farmerQrCreation
  }
];

export default farmerQrCreationRoutes;
