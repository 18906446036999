import { lazy } from 'react';

const CollectionMap = lazy(() => import('./collectionmap'));

const collectionmapRoutes = [
  {
    path: '/collectionmap',
    component: CollectionMap
  }
];

export default collectionmapRoutes;