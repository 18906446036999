import { lazy } from "react";

const Farmermap = lazy(() => import("./FarmermapV2"));

const farmermapRoutes = [
  {
    path: "/farmermap",
    component: Farmermap,
  },
];

export default farmermapRoutes;
