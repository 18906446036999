import { lazy } from 'react';
const Ordercompletioninq = lazy(() => import('./ordercompletioninq'));

const OrderCompletionInqRoutes = [
  {
    path: '/ordercompletioninq',
    component: Ordercompletioninq
  }
];

export default OrderCompletionInqRoutes;