import { lazy } from 'react';

const FieldVisit = lazy(() => import('./fieldvisitNew'));

const fieldvisitRoutes = [
  {
    path: '/fieldvisit',
    component: FieldVisit
  }
];

export default fieldvisitRoutes;