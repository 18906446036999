import { lazy } from 'react';

const BulkSms = lazy(() => import('./index'));

const BulkSmsRoutes = [
  {
    path: '/bulksms',
    component: BulkSms,
  },
];
export default BulkSmsRoutes;
