import { lazy } from 'react';
const LatexConsumption = lazy(() => import('./latexconsumption'));

const latexconsumptionRoutes = [
  {
    path: '/latexconsumption',
    component: LatexConsumption
  }
];

export default latexconsumptionRoutes;