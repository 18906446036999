import { lazy } from 'react';
const Wip = lazy(() => import('./Wip'));

const wipRoutes = [
  {
    path: '/wip',
    component: Wip
  }
];

export default wipRoutes;