import { lazy } from 'react';

const HrSystem = lazy(() => import('./empregistration'));

const empregistrationRoutes = [
  {
    path: '/empregistration',
    component: HrSystem
  }
];

export default empregistrationRoutes;