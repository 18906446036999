import { lazy } from 'react';
const PillowOrderConvert = lazy(() => import('./pilloworderconvert'));
const PillowOrderConvertNew = lazy(() => import('./pillowOrderConvertNew'));

const PillowOrderConvertRoutes = [
  {
    path: '/pilloworderconvert',
    component: PillowOrderConvertNew
  }
];

export default PillowOrderConvertRoutes;
