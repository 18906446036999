import { lazy } from "react";

// const Vessel = lazy(() => import("./Vessel"));
const newLoadingDetails = lazy(() => import('./newLoadingDetails'))

const vesselRoutes = [
  {
    path: "/vessel",
    component: newLoadingDetails,
  },
];

export default vesselRoutes;
