import { lazy } from 'react';

const Grnnopo = lazy(() => import('./Grnnopo'));

const grnnopoRoutes = [
  {
    path: '/grnlatex',
    component: Grnnopo
  }
];

export default grnnopoRoutes;
