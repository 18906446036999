import { lazy } from 'react';
const OrderTransfer = lazy(() => import('./OrderTransfer'));

const OrderTransferRoutes = [
  {
    path: '/ordertransfer',
    component: OrderTransfer
  }
];

export default OrderTransferRoutes;