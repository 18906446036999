import { lazy } from 'react';

const Vehicle = lazy(() => import('./index'));

const VehicleRoutes = [
  {
    path: '/vehicle',
    component: Vehicle
  }
];
export default VehicleRoutes;
