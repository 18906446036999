import { lazy } from 'react';

const QcItemRejection = lazy(() => import('./qcitemrection'));

const QcItemRejectionRoutes = [
  {
    path: '/qcorderrejection',
    component: QcItemRejection
  }
];

export default QcItemRejectionRoutes;