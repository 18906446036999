import { lazy } from "react";

const PriceUpdateApproval = lazy(() => import("./PriceUpdateApproval"));

const priceUpdateApproval = [
  {
    path: "/priceupdateapproval",
    component: PriceUpdateApproval,
  },
];

export default priceUpdateApproval;