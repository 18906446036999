import { lazy } from 'react';

const FabTable = lazy(() => import('./index'));

const fabTableRoutes = [
  {
    path: '/fabtable',
    component: FabTable,
  },
];

export default fabTableRoutes;
