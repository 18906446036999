import { lazy } from 'react';

const TransToLocal = lazy(() => import('./index'));

const TransToLocalRoutes = [
  {
    path: '/transtolocal',
    component: TransToLocal,
  },
];
export default TransToLocalRoutes;
