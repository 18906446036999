const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id:
      'us-east-1:139f4c51-cc0a-4382-a38a-8da035c386fa',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_IdZ42uLHX',
    aws_user_pools_web_client_id: '6ucd28h4rtfnusg683koef4pp9',
    oauth: {},
    aws_cognito_login_mechanisms: ['PREFERRED_USERNAME'],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_appsync_graphqlEndpoint:
      'https://bsy7wx2jnza2bcjqrdj5p7c2fi.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-jmthvye455a7fm3qgfypdw6k74',
    aws_user_files_s3_bucket: 'erp4cb7a54324bd44fdb47433b861d0c63f45525-prod',
    aws_user_files_s3_bucket_region: 'us-east-1',
  };
  
  export default awsmobile;