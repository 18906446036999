import { lazy } from 'react';

const TranHeader = lazy(() => import('./index'));

const TranHeaderRoutes = [
  {
    path: '/tranheader',
    component: TranHeader,
  },
];
export default TranHeaderRoutes;
