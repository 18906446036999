import { lazy } from 'react';
const barcodeInq = lazy(() => import('./barcodeInq'));

const barcodeInqRoutes = [
  {
    path: '/barcodeInq',
    component: barcodeInq
  }
];

export default barcodeInqRoutes;
