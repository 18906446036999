import { lazy } from 'react';
const Dashboard = lazy(() => import('./dashboard'));

const chartDashboardRoutes = [
  {
    path: '/chartdashboard',
    component: Dashboard,
  }
];

export default chartDashboardRoutes;