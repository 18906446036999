import { lazy } from 'react';
const Lamination = lazy(() => import('./lamination'));

const LaminationRoutes = [
  {
    path: '/lamination',
    component: Lamination
  }
];

export default LaminationRoutes;