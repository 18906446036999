import { lazy } from "react";
const RlaNew = lazy(() => import("./RlaNew"));

const rlaRoutes = [
  {
    path: "/rla",
    component: RlaNew,
  },
];

export default rlaRoutes;
