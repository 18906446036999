import { lazy } from 'react';
const HexagonStocks = lazy(() => import('./hexagonstocks'));

const hexagonStockRoutes = [
  {
    path: '/hexagonstock',
    component: HexagonStocks
  }
];

export default hexagonStockRoutes;