import { lazy } from 'react';

const OrdercompletionV3 = lazy(() => import('./OrdercompletionV3'));

const OrdercompletionV3Routes = [
  {
    path: '/ordercompletionv3',
    component: OrdercompletionV3
  }
];

export default OrdercompletionV3Routes;
