import { lazy } from 'react';
const PurchOrdInquiry = lazy(() => import('./PurchOrdInquiry'));

const PurchOrdInquiryRoutes = [
  {
    path: '/purchordinq',
    component: PurchOrdInquiry
  }
];

export default PurchOrdInquiryRoutes;