import { lazy } from 'react';

const fabricators = lazy(() => import('./index'));

const fabricatorsRoutes = [
  {
    path: '/fabricators',
    component: fabricators,
  },
];

export default fabricatorsRoutes;
