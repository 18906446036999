import { lazy } from 'react';

const FabTableAllocation = lazy(() => import('./index'));

const fabTableAllocationRoutes = [
  {
    path: '/fabtableallocation',
    component: FabTableAllocation,
  },
];

export default fabTableAllocationRoutes;
