import { lazy } from 'react';
const calender = lazy(() => import('./cal'));

const calRoutes = [
  {
    path: '/calender',
    component: calender
  }
];

export default calRoutes;