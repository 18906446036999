import { lazy } from 'react';
const setup = lazy(() => import('./setup'));

const setupRoutes = [
  {
    path: '/empsetup',
    component: setup
  }
];

export default setupRoutes;