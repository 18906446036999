import { lazy } from 'react';
const NewSalesOrder = lazy(() => import('./NewSalesOrder'));

const newSalesOrderRoutes = [
  {
    path: '/newsalesorder',
    component: NewSalesOrder
  }
];

export default newSalesOrderRoutes;
