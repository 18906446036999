import { lazy } from 'react';

const Fabricationfinalload = lazy(() => import('./Fabricationfinalload'));

const fabricationfinalloadRoutes = [
  {
    path: '/fabricationfinalloadlocal',
    component: Fabricationfinalload,
  },
];

export default fabricationfinalloadRoutes;
