import { lazy } from 'react';
const PillowStockInq = lazy(() => import('./pillowstockinq'));

const PillowStockInqRoutes = [
  {
    path: '/pillowstockinq',
    component: PillowStockInq
  }
];

export default PillowStockInqRoutes;