import { lazy } from 'react';

const Creditors = lazy(() => import('./Creditors'));

const creditorsRoutes = [
  {
    path: '/aspr',
    component: Creditors,
  },
];

export default creditorsRoutes;
