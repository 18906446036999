import { lazy } from 'react';

const fgreceivednote = lazy(() => import('./index'));

const fgreceivednoteRoutes = [
  {
    path: '/fgreceivednote',
    component: fgreceivednote,
  },
];

export default fgreceivednoteRoutes;
