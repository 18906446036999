import { lazy } from 'react';
const CycleCountPosting = lazy(() => import('./cyclecountposting'));

const cyclecountpostingRoutes = [
  {
    path: '/cyclecountposting',
    component: CycleCountPosting
  }
];

export default cyclecountpostingRoutes;