import { lazy } from 'react';
const Mis = lazy(() => import('./Mis'));

const MisRoutes = [
  {
    path: '/mis',
    component: Mis
  }
];

export default MisRoutes;
