import { lazy } from "react";

const PriceUpdateByCustomer = lazy(() => import("./priceUpdateByCustomer"));

const pricebycustomer = [
  {
    path: "/priceupdatebycustomer",
    component: PriceUpdateByCustomer,
  },
];

export default pricebycustomer;
