import { lazy } from 'react';

const PillowFilling = lazy(() => import('./index'));

const pillowFillingRoutes = [
  {
    path: '/pillowfilling',
    component: PillowFilling,
  },
];
export default pillowFillingRoutes;
