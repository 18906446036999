import localStorageService from './localStorageService';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { listUserCompanyRoles } from '../../graphql/queries';

import instance from '../config/axiosConfig';

class JwtAuthService {
  loginWithEmailAndPassword = async (username, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const userAuth = await Auth.signIn(username, password);
        const tokenInfo = await Auth.currentSession();

        const accessToken = tokenInfo?.accessToken?.jwtToken;
        if (accessToken) {
          localStorageService.setItem(
            'ACCESS_TOKEN',
            tokenInfo?.accessToken?.jwtToken
          );
        } else {
          localStorageService.removeItem('ACCESS_TOKEN');
        }
        const attributes = userAuth.attributes;
        await this.updateWhiteList();
        let uerObj = null;
        let userCompanyRoles = null;

        const { data } = await instance.get(
          `/users/cognitoId/${attributes.sub}`
        );
        uerObj = data.user;

        if (uerObj.fulacs !== 'YES') {
          userCompanyRoles = await API.graphql(
            graphqlOperation(listUserCompanyRoles, {
              filter: {
                userID: {
                  eq: uerObj.id,
                },
              },
              limit: 1000000,
            })
          );
          if (userCompanyRoles) {
            const comRoles = userCompanyRoles.data.listUserCompanyRoles.items;

            await Promise.all(
              comRoles.map(async (companeyObj) => {
                const { data } = await instance.get(
                  `/companies/${companeyObj.companyID}`
                );
                companeyObj.company = data.company;
              })
            );
          } else {
            resolve();
          }
        }

        const user = {
          uInfo: uerObj ? uerObj : null,
          userCompanyRoles: userCompanyRoles
            ? userCompanyRoles.data.listUserCompanyRoles.items
            : null,
          userId: uerObj.id,
          cognitoId: attributes.sub,
          role: attributes['custom:user_type'],
          displayName: attributes.name,
          email: attributes.email,
          photoURL: '/assets/images/face-7.jpg',
          phone: attributes.phone_number,
          token: tokenInfo.accessToken.jwtToken,
        };

        resolve(user);
      } catch (error) {
        resolve();
      }
    }).then((data) => {
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  updateWhiteList = async () => {
    const { data } = await instance.get('/common/whitelist');
    let whitelist;
    if(data && data.length > 0){
      whitelist = data.map((item) => item.path);
    }
    localStorage.setItem('whitelist', JSON.stringify(whitelist));
  }

  loginWithToken = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const userInfo = await Auth.currentUserInfo();
        const tokenInfo = await Auth.currentSession();

        const attributes = userInfo.attributes;

        if (tokenInfo) {
          let uerObj = null;
          let userCompanyRoles = null;

          const { data } = await instance.get(
            `/users/cognitoId/${attributes.sub}`
          );
          uerObj = data.user;
          if (uerObj?.fulacs !== 'YES') {
            userCompanyRoles = await API.graphql(
              graphqlOperation(listUserCompanyRoles, {
                filter: {
                  userID: {
                    eq: uerObj.id,
                  },
                },
                limit: 1000000,
              })
            );
          }
          if (userCompanyRoles) {
            const comRoles = userCompanyRoles.data.listUserCompanyRoles.items;

            await Promise.all(
              comRoles.map(async (companeyObj) => {
                const { data } = await instance.get(
                  `/companies/${companeyObj.companyID}`
                );
                companeyObj.company = data.company;
              })
            );
          }

          const user = {
            uInfo: uerObj ? uerObj : null,
            userCompanyRoles: userCompanyRoles
              ? userCompanyRoles.data.listUserCompanyRoles.items
              : null,
            userId: uerObj.id,
            cognitoId: attributes.sub,
            role: attributes['custom:user_type'],
            displayName: attributes.name,
            email: attributes.email,
            photoURL: '/assets/images/face-7.jpg',
            phone: attributes.phone_number,
            token: tokenInfo.accessToken.jwtToken,
          };
          resolve(user);
        } else {
          resolve();
        }
      } catch (error) {
        resolve();
      }
    }).then((data) => {
      if (data) {
        this.setSession(data.token);
        this.setUser(data);
      }

      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem('jwt_token', token);
    } else {
      localStorage.removeItem('jwt_token');
    }
  };
  setUser = (user) => {
    localStorageService.setItem('auth_user', user);
  };
  removeUser = () => {
    localStorage.removeItem('auth_user');
  };
}

export default new JwtAuthService();
