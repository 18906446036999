import { lazy } from 'react';
const ManualVoucherEf = lazy(() => import('./manuVoucherEf'));

const manualVoucherEfRoutes = [
  {
    path: '/manuvouchef',
    component: ManualVoucherEf
  }
];

export default manualVoucherEfRoutes;