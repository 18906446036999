import { lazy } from 'react';
const itemrewip = lazy(() => import('../itemrewip/itemrewip'));

const itemrewipRoutes = [
  {
    path: '/itemrewip',
    component: itemrewip
  }
];

export default itemrewipRoutes;