import { lazy } from 'react';
const fgbomin = lazy(() => import('./fgbomin'));

const fgbominRoutes = [
  {
    path: '/fgbomin',
    component: fgbomin
  }
];

export default fgbominRoutes;
