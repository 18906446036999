import { lazy } from 'react';
const ltxsuppayproc = lazy(() => import('./ltxsuppayproc'));

const ltxsuppayprocRoutes = [
  {
    path: '/ltxsuppayproc',
    component: ltxsuppayproc
  }
];

export default ltxsuppayprocRoutes;