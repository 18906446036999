import { lazy } from 'react';

const customerReturn = lazy(() => import('./index'));

const customerReturnRoutes = [
  {
    path: '/customerreturn',
    component: customerReturn,
  },
];
export default customerReturnRoutes;
