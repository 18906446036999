import { lazy } from "react";

const verticalRecutter = lazy(() => import("./verticalRecutter"));

const verticalcutterRoutes = [
  {
    path: "/ordertransfers",
    component: verticalRecutter,
  },
];

export default verticalcutterRoutes;
