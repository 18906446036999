import { lazy } from 'react';

const AssetGroupManager = lazy(() => import('./assetGroupManager'));

const assetGroupManagerRoutes = [
  {
    path: '/assetgroupmanager',
    component: AssetGroupManager
  }
];

export default assetGroupManagerRoutes;