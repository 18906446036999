import { lazy } from 'react';

const PosInvoice = lazy(() => import('./index'));

const PosInvoiceRoutes = [
  {
    path: '/posinvoice',
    component: PosInvoice,
  },
];
export default PosInvoiceRoutes;
