import { lazy } from 'react';
const ProdIncentiveView = lazy(() => import('./ProdIncentiveView'));

const ProdIncentiveViewRoutes = [
  {
    path: '/prodincenview',
    component: ProdIncentiveView
  }
];

export default ProdIncentiveViewRoutes;