import { lazy } from 'react';
const ProdIncentive = lazy(() => import('./ProdIncentive'));

const ProdIncentiveRoutes = [
  {
    path: '/prodincentive',
    component: ProdIncentive
  }
];

export default ProdIncentiveRoutes;