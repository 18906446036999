import { lazy } from "react";

const LocalFG = lazy(() => import("./localfg"));

const localFGRoutes = [
  {
    path: "/localfg",
    component: LocalFG,
  },
];

export default localFGRoutes;