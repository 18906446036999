import { lazy } from 'react';

const newReports = lazy(() => import('./newReports'));

const newReportsRoutes = [
  {
    path: '/new/report/:id',
    component: newReports
  }
];

export default newReportsRoutes;