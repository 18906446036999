import { lazy } from 'react';
const LtxPriceUpdateNew = lazy(() => import('./LtxPriceUpdateNew'));

const LtxPriceUpdateNewRoutes = [
  {
    path: '/ltxpriceupdate/new',
    component: LtxPriceUpdateNew
  }
];

export default LtxPriceUpdateNewRoutes;