import { lazy } from 'react';
const LatexArrival = lazy(() => import('./latexarrival'));

const latexarrivalRoutes = [
  {
    path: '/latexarrival',
    component: LatexArrival
  }
];

export default latexarrivalRoutes;