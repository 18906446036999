import { lazy } from 'react';

const collectionList = lazy(() => import('./collectionList'));
const collectionListNew = lazy(() => import('./collectionListNew'));

const colelctionRoutes = [
  {
    path: '/collectionlist',
    component: collectionListNew
  }
];

export default colelctionRoutes;
