import { lazy } from 'react';

const Grnnopo = lazy(() => import('./Grnnopo'));

const ronRoutes = [
  {
    path: '/ron',
    component: Grnnopo,
  },
];

export default ronRoutes;
