import { lazy } from 'react';
const leave = lazy(() => import('./leave'));

const leaveRoutes = [
  {
    path: '/leave',
    component: leave
  }
];

export default leaveRoutes;