import { lazy } from 'react';

const pillowDryoutput = lazy(() => import('./index'));

const pillowDryoutputRoutes = [
  {
    path: '/pillowdryoutput',
    component: pillowDryoutput,
  },
];
export default pillowDryoutputRoutes;
