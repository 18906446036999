import { lazy } from 'react';
const PaymentAttView = lazy(() => import('./PaymentAttView'));

const PaymentAttViewRoutes = [
  {
    path: '/paymentattview',
    component: PaymentAttView
  }
];

export default PaymentAttViewRoutes;