import { lazy } from 'react';

const Fabrication = lazy(() => import('./index'));

const FabricationRoutes = [
  {
    path: '/fabrication',
    component: Fabrication,
  },
];
export default FabricationRoutes;
