import { lazy } from 'react';
const SmsPayment = lazy(() => import('./SmsPayment'));

const SmsPaymentRoutes = [
  {
    path: '/smspayment',
    component: SmsPayment
  }
];

export default SmsPaymentRoutes;
