import { lazy } from 'react';
const ltxpriceexport = lazy(() => import('./ltxpriceexport'));

const ltxpriceexportRoutes = [
  {
    path: '/ltxpriceexport',
    component: ltxpriceexport
  }
];

export default ltxpriceexportRoutes;