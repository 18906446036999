import { lazy } from 'react';

const fixedassetsmaster = lazy(() => import('./fixedassetsmaster'));

const fixedassetsmasterRoutes = [
  {
    path: '/fixedassets',
    component: fixedassetsmaster
  }
];

export default fixedassetsmasterRoutes;