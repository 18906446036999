import { lazy } from 'react';

const StdtprodPillow = lazy(() => import('./StdtprodPillow'));

const stdtprodPillowRoutes = [
  {
    path: '/stdtprodpillow',
    component: StdtprodPillow,
  },
];

export default stdtprodPillowRoutes;
