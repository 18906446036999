import localStorageService from 'app/services/localStorageService';
import axios from 'axios';
import { format } from 'date-fns';
import swal from 'sweetalert2';

const hostname = window.location.hostname;
const localHostnames = ['localhost', '192.168.1.6'];
const apiHost = 'https://api.hexagonasia.com/';

const baseURL = localHostnames.includes(hostname)
  ? 'http://localhost:5000/'
  : hostname === 'erp.hexagonasia.com'
  ? apiHost
  : hostname === 'develop.hexagonasia.com'
  ? `${apiHost}api/development`
  : apiHost;

const instance = axios.create({
  baseURL
});

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Access-Control-Max-Age': '7200'
};

instance.defaults.headers.post['Content-Type'] = DEFAULT_HEADERS['Content-Type'];
instance.defaults.headers = DEFAULT_HEADERS;

let whitelist = [
  '/session/signin/1',
  '/session/signin',
  '/home',
  '/change-password',
  '/select-company',
  '/session/404',
  '/fabricators',
  '/priceupdateapproval',
  '/orderflowrla',
  '/processpaymnet/',
];

whitelist = JSON.parse(localStorage.getItem('whitelist')) || whitelist;

const checkIfPathExists = (nav, path) => {
  if (nav.type === 'link' && (path.startsWith(nav.path) || nav.path.startsWith(path) || nav.path === path)) {
    return true;
  }
  if (nav.sub && Array.isArray(nav.sub)) {
    return nav.sub.some((subNav) => checkIfPathExists(subNav, path));
  }
  return false;
};

const checkIfNavValid = (nav) => {
  if (!nav) return false;

  const navList = localStorage.getItem('navigations');
  if (navList) {
    const navArray = JSON.parse(navList);
    return navArray.some((navItem) => checkIfPathExists(navItem, nav));
  }
  return false;
};

const handleAuthError = (message, redirectUrl = '/session/signin') => {
  swal
    .fire({
      title: 'Error!',
      text: message,
      icon: 'error',
      confirmButtonText: 'Close'
    })
    .then(() => {
      if (redirectUrl !== '') window.location = redirectUrl;
    });
};

const sendLogAndRedirect = async (path, attemptBy) => {
  try {
    await instance.post(
      'common/logs',
      {
        logType: 'ACCESS_DENIED',
        path,
        attemptBy,
        date: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        logdate: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
      },
      {
        headers: {
          authorization: `Bearer ${localStorageService.getItem('ACCESS_TOKEN')}`
        }
      }
    );
  } finally {
    window.location = '/home';
  }
};

instance.interceptors.request.use(
  (request) => {
    const currentPath = window.location.pathname;
    const authUser = JSON.parse(localStorage.getItem('auth_user') || '{}');
    const access = authUser?.uInfo?.fulacs || 'NO';
    
    const inWhitelist = whitelist.some((path) => currentPath.startsWith(path));

    if ( inWhitelist || checkIfNavValid(currentPath) || access === 'YES') {
      request.headers.authorization = `Bearer ${localStorageService.getItem('ACCESS_TOKEN')}`;
      return request;
    } else {
      swal
        .fire({
          title: 'Nice Try!',
          text: 'You Have No Access To This Page',
          icon: 'error',
          confirmButtonText: 'Go To Home',
          footer: '<p>Contact System Administrator</p>'
        })
        .then(() => {
          sendLogAndRedirect(`${currentPath}`, `${authUser?.uInfo?.userId}`);
        });
      request.headers.authorization = `Bearer ${localStorageService.getItem('ACCESS_TOKEN')}`;
      return request;
    }
  },
  (error) => {
    handleAuthError('Please check your internet connection or contact the administrator', '/home');
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error?.response?.status;
    if (status === 401 || status === 403) {
      handleAuthError('Unauthorized to perform this action', '/session/signin');
      localStorage.clear();
    } else if (status === 502) {
      
    } 
    else {
      handleAuthError('Please check your internet connection or contact administrator', '');
    }
    return Promise.reject(error);
  }
);

export default instance;
