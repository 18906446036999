import {lazy} from "react";

const OrderExport = lazy(() => import("./OrderExport"));
const OrderExportNew = lazy(() => import("./OrderExportNew"));

const orderExportRoutes = [
    {
        path: "/orderexport",
        component: OrderExportNew,
    }
];

export default orderExportRoutes;