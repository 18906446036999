import { lazy } from 'react';

const SampleApproval = lazy(() => import('./sampleApproval'));

const sampleApprovalRoutes = [
  {
    path: '/sampleapproval',
    component: SampleApproval
  }
];

export default sampleApprovalRoutes;