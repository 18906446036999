import { lazy } from 'react';
const WipIn = lazy(() => import('./WipIn'));

const WipInRoutes = [
  {
    path: '/wipin',
    component: WipIn
  }
];

export default WipInRoutes;