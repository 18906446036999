import { lazy } from 'react';
const Barcodeprinter = lazy(() => import('./barcodeprinter'));

const barcodeprinterRoutes = [
  {
    path: '/barcodeprinter',
    component: Barcodeprinter
  }
];

export default barcodeprinterRoutes;