import { lazy } from 'react';

const BulkSmsCustomMessage = lazy(() => import('./bulkSmsCustomerMessage'));

const BulkSmsCustomMessageRoutes = [
  {
    path: '/bulksmscustommessage',
    component: BulkSmsCustomMessage,
  },
];
export default BulkSmsCustomMessageRoutes;
