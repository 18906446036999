import { lazy } from 'react';

const PaymentSetOff = lazy(() => import('./paymentsetoff'));

const paymentsetoffRoutes = [
  {
    path: '/paymentsetoff',
    component: PaymentSetOff
  }
];

export default paymentsetoffRoutes;