import { lazy } from 'react';

const MealIssue = lazy(() => import('./mealissue'));

const mealissueRoutes = [
  {
    path: '/mealissue',
    component: MealIssue
  }
];

export default mealissueRoutes;