import { lazy } from 'react';

const SupplierQRGenerator = lazy(() => import('./supplierqrgenerator'));

const supplierQRGeneratorRoutes = [
  {
    path: '/supqrgen',
    component: SupplierQRGenerator
  }
];

export default supplierQRGeneratorRoutes;