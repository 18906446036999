import { lazy } from 'react';

const PillowQC = lazy(() => import('./index'));

const PillowQCRoutes = [
  {
    path: '/pillowqc',
    component: PillowQC,
  },
];
export default PillowQCRoutes;
