import {lazy } from 'react';

const bom = lazy(() => import('./bom'));

const bomRoutes = [
    {
        path: '/bom',
        component: bom,
    }

];

export default bomRoutes;