import { lazy } from "react";

const OrderCompletionPillow = lazy(() => import("./orderCompletionPillow"));

const OrderCompletionPillowRoutes = [
  {
    path: "/completionpillow",
    component: OrderCompletionPillow,
  },
];

export default OrderCompletionPillowRoutes;