import { lazy } from 'react';
const EmployeeCount = lazy(() => import('./EmployeeCount'));

const EmployeeCountRoutes = [
  {
    path: '/employeecount',
    component: EmployeeCount
  }
];

export default EmployeeCountRoutes;