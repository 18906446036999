import { lazy } from 'react';

const Materialissuenote = lazy(() => import('./Materialissuenote'));

const materialissuenoteRoutes = [
  {
    path: '/mrilatex',
    component: Materialissuenote
  }
];

export default materialissuenoteRoutes;
