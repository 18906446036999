import { lazy } from 'react';
const CycleCountUpdate = lazy(() => import('./cyclecountupdate'));

const cyclecountupdateRoutes = [
  {
    path: '/cyccountupdt',
    component: CycleCountUpdate
  }
];

export default cyclecountupdateRoutes;
