import { lazy } from 'react';
const SupPaymentInq = lazy(() => import('./suppaymentinq'));

const SupPaymentInqRoutes = [
  {
    path: '/suppaymentinq',
    component: SupPaymentInq
  }
];

export default SupPaymentInqRoutes;