import { lazy } from 'react';

const BulkPrint = lazy(() => import('./index'));

const BulkPrintRoutes = [
  {
    path: '/bulkPrint',
    component: BulkPrint,
  },
];
export default BulkPrintRoutes;
