import { lazy } from 'react';
const ProductionEntryCharts = lazy(() => import('./productionentrycharts'));
const ProductionEntryChartNew = lazy(() => import('./ProductionEntryChartNew'));

const productionentrychartsRoutes = [
  {
    path: '/productionentrycharts',
    component: ProductionEntryChartNew
  }
];

export default productionentrychartsRoutes;