import { lazy } from "react";

const LocalWip = lazy(() => import("./localwip"));

const localwipRoutes = [
  {
    path: "/localwip",
    component: LocalWip,
  },
];

export default localwipRoutes;
